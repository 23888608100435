import { FC } from "react";
import styled from "styled-components";

export const StyledLink = styled.a`
  color: var(--text-muted);
  display: inline-block;
  text-decoration: none;
  text-transform: lowercase;
  font-weight: 600;
  transition: all 0.2s ease-in-out;

  &:hover {
    color: var(--text-primary);
  }
`;

type LinkProp = {
    label: string,
    url: string
}

const Link: FC<LinkProp> = ({ label, url }) => {
    return <StyledLink href={url}>{label}</StyledLink>
}

export default Link;