import {FC} from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  border: 0;
  outline: 0;
  color: var(--text-secondary);
  font-size: 1em;
  font-weight: 600;
  text-transform: lowercase;
  opacity: 0.75;
  cursor: pointer;
  background-color: var(--bg-secondary);
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1em 2em;
  transition: all 0.15s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

type ButtonProp = {
    label: string
}

export const Button: FC<ButtonProp> = ({ label }) => {
    return <StyledButton>{label}</StyledButton>
}