import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Button } from "../component/Button";
import GlobalLink from "../component/Link";
import { Link } from "react-router-dom";

/** styled components start */
const StyledContainer = styled.div`
  background-color: var(--bg-primary);
  border-top: 4px solid rgba(0, 0, 0, 0.05);
  flex-direction: column;
  justify-content: center;
  height: auto;

  #legal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #legal_links {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 2em;
    }
  }

  /* xs */
  @media (min-width: 375px) {
    display: none;
  }

  /* sm */
  @media (min-width: 640px) {
  }

  /* md */
  @media (min-width: 768px) {
  }

  /* lg */
  @media (min-width: 1024px) {
    display: flex;
  }

  /* xl */
  @media (min-width: 1280px) {
  }

  /* 2xl */
  @media (min-width: 1536px) {
  }
`;

const StyledContent = styled.div`
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
  display: grid;
  grid-template-areas: 
  ". logo ."
  "company team legal"
  "copy copy copy";
  justify-items: center;
  align-items: center;

  div {
    height: 70%;
    
    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      
      li {
        color: var(--text-secondary);
      }

      a {
        text-decoration: none;
        font-weight: 600;
        color: var(--text-secondary);
      }
    }
  }

  .logo {
    grid-area: logo;
  }

  .company {
    grid-area: company;
  }

  .team {
    grid-area: team;

    ul {
      gap: 5px;
    }
  }

  .legal {
    grid-area: legal;
  }

  .copy {
    grid-area: copy;
    display: flex;
    border-top: 4px solid var(--bg-secondary);
    border-radius: 2px;
    color: var(--text-secondary);
    align-items: center;
    gap: 60px;
    
    ul {
      line-height: 1.5;
    }
  }
`;

const StyledLogo = styled.h2`
  grid-area: logo;
  font-family: "Pacifico", cursive;
  font-size: 2em;
  font-weight: 800;
  margin-bottom: 0;
`;

const StyledCopy = styled.p`
  grid-area: copy;
  color: var(--text-secondary);
  border-top: 1px solid var(--bg-secondary);
`;
/** styled components end */

type LogoProp = {
  label: string;
};

type ButtonProp = {
  label: string;
};

/** react components start */
const Logo: FC<LogoProp> = ({ label }) => {
  return <StyledLogo>{label}</StyledLogo>;
};

const FooterButton: FC<ButtonProp> = ({ label }) => {
  return <Button label={label} />;
};

const FooterParent = ({ children }: { children?: ReactNode }) => {
  return <div>{children}</div>;
};

/** assigning children */
FooterParent.Logo = Logo;
FooterParent.Button = FooterButton;

export const Footer: FC = () => {
  return (
    <StyledContainer>
      <StyledContent>
        <div className="logo"><FooterParent.Logo label={"ABFH"} /></div>

        <div className="company">
          <ul className="company-list">
            <h4>Company</h4>
            <li><a href="">About</a></li>
          </ul>
        </div>

        <div className="team">
          <ul className="team-list">
            <h4 className="team-title">Team</h4>
            <li className="team-list__member">Toni Reyes - President</li>
            <li>Chera Jessup, Executive Director</li>
            <li>Giselle Whiskey - Director</li>
          </ul>
        </div>

        <div className="legal">
          <ul>
            <h4>legal</h4>
            <li><a href="">Privacy</a></li>
            <li><a href="">Terms</a></li>
          </ul>
        </div>

        <div className="copy">
          <p>&copy; {new Date().getFullYear()} A Breath From Heaven Inc. All Rights Reserved.</p>
          <ul>
            <li>(386) 753-7749</li>
            <li>abreathfromheaven@gmail.com</li>
            <li>EIN: 81-1413774</li>
          </ul>
        </div>
      </StyledContent>
    </StyledContainer>
  );
};
/** react components end */
