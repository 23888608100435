import { FC, ReactNode } from "react";
import styled from "styled-components";
import { Button } from "../component/Button";
import GlobalLink from "../component/Link";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

/** styled components start **/
const StyledContainer = styled.div`
  background-color: var(--bg-primary);
  justify-content: space-evenly;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  text-decoration: none;

/* xs */
@media (min-width: 375px) {
    display: none;
}

/* sm */
@media (min-width: 640px) {
}

/* md */
@media (min-width: 768px) {
}

/* lg */
@media (min-width: 1024px) {
    display: flex;
    width: 100vw;
    height: 4.5em;
}

/* xl */
@media (min-width: 1280px) {
}

/* 2xl */
@media (min-width: 1536px) {
}
`;

const StyledList = styled.ul`
  display: flex;
  list-style: none;
  width: 50vw;
  align-items: center;
  justify-content: center;
  gap: 2em;
`;

const StyledLogo = styled.a`
    color: var(--text-primary);
    display: inline-block;
    font-family: 'Pacifico', cursive;
    font-size: 2em;
    font-weight: 500;
    letter-spacing: 3px;
    text-decoration: none;
`;
/** styled components end **/

type LogoProp = {
    label: string,
    url: string
}

/** react components start **/
const Logo: FC<LogoProp> = ({ label, url }) => {
    return <StyledLogo href={url}>{label}</StyledLogo>
}

const NavBarParent = ({ children }: { children?: ReactNode }) => {
    return <div>{children}</div>
}

/** assign children */
NavBarParent.Link = GlobalLink;
NavBarParent.Button = Button;
NavBarParent.Logo = Logo;

export const NavigationBar: FC = () => {
    return (
        <StyledContainer>
            <Link to={'/'}><motion.div whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}><NavBarParent.Logo label={"ABFH"} url={""} /></motion.div></Link>

            <StyledList>
                {/* <Link to={'/about'}><NavBarParent.Link label={"About"} url={""} /></Link> */}
                {/* <Link to={'/#programs'}><NavBarParent.Link label={"Programs"} url={"#programs"} /></Link> */}
                {/* <Link to={'/donate'}><NavBarParent.Link label={"Donate"} url={""} /></Link> */}
                {/* <Link to={'/contact'}><NavBarParent.Link label={"Contact"} url={""} /></Link> */}
            </StyledList>

            <a href="mailto:abreathfromheaven@gmail.com"><NavBarParent.Button label={"Contact"} /></a>
        </StyledContainer>
    );
}
/** react components end **/