import { Footer } from "../../compound/Footer";
import { NavigationBar } from "../../compound/NavigationBar";

export const About = () => {
  return (
    <div>
      <NavigationBar />
      <Footer />
    </div>
  );
};
