import { FC } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { Button } from "../Button";

/** Styled Components Start */
const Container = styled.section`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100vh;
  text-align: center;
  background: rgba(0, 0, 0, 0.5);
`;

const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const ErrorMessage = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.2;
  color: white;

  h1 {
      text-align: center;
      max-width: 30ch;
      margin-top: 2em;
  }

  p {
      font-size: 1.2em;
      margin-bottom: 2em;
  }
`;

const BgImage = styled.div`
  height: 100%;
  background-image: url('/img/cross_bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
`;
/** Styled Components End */

export const PageNotFound: FC = () => {
  return (
      <Container>
          <Content>
              <ErrorMessage>
                  <h1>The page you were looking for cannot be found.</h1>
                  <p>Please return to the home page by clicking the button below</p>
                  
                  <Link to={"/"}>
                      <Button label={"home"} />
                  </Link>
              </ErrorMessage>
              <BgImage />
          </Content>
      </Container>
  );
};