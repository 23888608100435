import { FC } from "react";
import { HomeHero } from "./HomeHero";
import { HomeAbout } from "./HomeAbout";
import { HomeFeatures } from "./HomeFeatures";
import { HomePrograms } from "./HomePrograms";
import { NavigationBar } from "../../compound/NavigationBar";
import { Footer } from "../../compound/Footer";
import { UnderDevelopmentMessage } from "../UnderDevelopmentMessage";

export const Home: FC = () => {
    return (
        <div>
            <NavigationBar />
            <HomeHero />
            <HomeAbout />
            <HomeFeatures />
            <HomePrograms />
            <Footer />
        </div>
    );
}