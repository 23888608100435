import { NavigationBar } from "./compound/NavigationBar";
import { Footer } from "./compound/Footer";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Home } from "./component/home/Home";
import { Donate } from "./component/donate/Donate";
import { About } from "./component/about/About";
import { UnderDevelopmentMessage } from "./component/UnderDevelopmentMessage";
import { PageNotFound } from "./component/not-found/PageNotFound";

export const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="strangers"
          element={
            <>
              <NavigationBar />
              <UnderDevelopmentMessage />
              <Footer />
            </>
          }
        />
        <Route
          path="orphans"
          element={
            <>
              <NavigationBar />
              <UnderDevelopmentMessage />
              <Footer />
            </>
          }
        />
        <Route
          path="prisoners"
          element={
            <>
              <NavigationBar />
              <UnderDevelopmentMessage />
              <Footer />
            </>
          }
        />
        <Route
          path="widows"
          element={
            <>
              <NavigationBar />
              <UnderDevelopmentMessage />
              <Footer />
            </>
          }
        />
        <Route path="donate" element={<><Donate /></>} />
        <Route path="about" element={<><About /></>} />
        <Route path="volunteer" element={<PageNotFound />} /> {/* temporary route element */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};
