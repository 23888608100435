import styled from "styled-components";
import { FC, ReactNode } from "react";
import { motion } from "framer-motion";

const donationEmail: string = 'Abreathfromheaven@gmail.com';

/** styled components start */
const StyledContainer = styled.section`
  background-color: var(--bg-secondary);
  height: 100vh;
  width: 100vw;
  padding: 0 1em;
`;

const StyledOptionsContainer = styled.div`
  height: 100%;
  width: 100%;

  .options__wrapper {
    height: 100%;
    width: 100%;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    justify-content: space-evenly;
    justify-items: center;
    align-content: space-evenly;
    align-items: center;

    .header__wrapper {
      grid-column: span 5;
    }
  }

  /* xs */
  @media (min-width: 375px) {
  }

  /* sm */
  @media (min-width: 640px) {
  }

  /* md */
  @media (min-width: 768px) {
  }

  /* lg */
  @media (min-width: 1024px) {
    .options__wrapper {
      display: grid;
      gap: 1em;
    }
  }

  /* xl */
  @media (min-width: 1280px) {
    gap: 2em;
  }

  /* 2xl */
  @media (min-width: 1536px) {
  }
`;

const StyledOption = styled.div<{ url: string }>`
  background: url(${(props) => props.url});
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 12px;
  padding: 0 1em;
  position: relative;

  .option-desc__wrapper {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 12px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  #payment-option {
    color: var(--selected);
  }

  h4 {
    color: white;
  }

  p {
    color: white;
    text-align: center;
    max-width: 40ch;
    line-height: 1.5;
  }

  code {
    background-color: white;
    border-radius: 1em;
    color: var(--selected);
    padding: 0.5em 3em;
  }

  /* lg */
  @media (min-width: 1024px) {
    height: 25em;
    width: 15em;
  }

  /* xl */
  @media (min-width: 1280px) {
    height: 30em;
    width: 18em;
  }

  /* 2xl */
  @media (min-width: 1536px) {
    height: 35em;
    width: 25em;
  }
`;

const StyledHeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const StyledHeader = styled.h2`
  font-size: 3em;
`;
const StyledSubHeader = styled.p`
  font-size: 1.5em;
`;
/** styled components end */

type OptionProps = {
  url: string;
  children?: ReactNode;
};

/** react components start */
const Option: FC<OptionProps> = ({ url, children }) => {
  return <StyledOption url={url}>{children}</StyledOption>;
};

export const DonateOptions: FC = () => {
  return (
    <StyledContainer>
      <StyledOptionsContainer>
        <div className="options__wrapper">
          {/** Heading start */}
          <StyledHeadingContainer className="header__wrapper">
            <StyledHeader>Donation Options</StyledHeader>
            <StyledSubHeader>
              Hover over the program you want to donate to, and follow the instruction.
            </StyledSubHeader>
          </StyledHeadingContainer>
          {/** Heading end */}

          <Option url="/img/orphans_card_bg.png">
            <motion.div
              className="option-desc__wrapper"
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
            >
              <motion.h4>Orphans</motion.h4>
              <motion.p>
                Wanna donate to our Orphans program? Send your donation via{" "}
                <span id="payment-option">Zelle</span> to the email below. Be
                sure to add the word <strong>orphans</strong> to the memo.
              </motion.p>
              <motion.code>{donationEmail}</motion.code>
            </motion.div>
          </Option>
          <Option url="/img/strangers_card_bg.png">
            <motion.div
              className="option-desc__wrapper"
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
            >
              <motion.h4>Strangers</motion.h4>
              <motion.p>
                Wanna donate to our Strangers program? Send your donation via{" "}
                <span id="payment-option">Zelle</span> to the email below. Be
                sure to add the word <strong>strangers</strong> to the memo.
              </motion.p>
              <motion.code>{donationEmail}</motion.code>
            </motion.div>
          </Option>
          <Option url="/img/prisoners_card_bg.png">
            <motion.div
              className="option-desc__wrapper"
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
            >
              <motion.h4>Prisoners</motion.h4>
              <motion.p>
                Wanna donate to our Orphans program? Send your donation via{" "}
                <span id="payment-option">Zelle</span> to the email below. Be
                sure to add the word <strong>prisoners</strong> to the memo.
              </motion.p>
              <motion.code>{donationEmail}</motion.code>
            </motion.div>
          </Option>
          <Option url="/img/widow_card_bg.png">
            <motion.div
              className="option-desc__wrapper"
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
            >
              <motion.h4>Widow</motion.h4>
              <motion.p>
                Wanna donate to our Widows program? Send your donation via{" "}
                <span id="payment-option">Zelle</span> to the email below. Be
                sure to add the word <strong>widow</strong> to the memo.
              </motion.p>
              <motion.code>{donationEmail}</motion.code>
            </motion.div>
          </Option>
        </div>
      </StyledOptionsContainer>
    </StyledContainer>
  );
};
/** react components end */
