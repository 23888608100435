import { FC } from "react";
import styled from "styled-components";
import { StyledLink } from "../Link";
import { motion } from "framer-motion";

/** styled components start */
const StyledContainer = styled.section`
  /* background-color: var(--bg-secondary); */
  background: linear-gradient(
    to bottom left,
    var(--bg-secondary),
    var(--bg-primary)
  );
  justify-content: center;
  align-items: center;

  /* lg */
  @media (min-width: 1024px) {
    display: flex;
    height: auto;
  }
`;

const StyledContent = styled.div`
  flex-direction: column;
  justify-content: center;
  align-items: center;

  /* xs */
  @media (min-width: 375px) {
    display: none;
  }

  /* sm */
  @media (min-width: 640px) {
  }

  /* md */
  @media (min-width: 768px) {
  }

  /* lg */
  @media (min-width: 1024px) {
    display: flex;
    height: 100%;
    max-width: 1024px;
  }

  /* xl */
  @media (min-width: 1280px) {
    max-width: 1280px;
  }

  /* 2xl */
  @media (min-width: 1536px) {
    max-width: 1536px;
  }
`;

const StyledHeader = styled.h2`
  font-size: 3em;
  font-weight: 700;
`;

const StyledProgramImage = styled.img`
  border-radius: 12px;
  height: 20em;
`;
const StyledProgramHeader = styled.h3`
  font-family: "Pacifico", cursive;
  font-size: 2em;
  font-weight: 700;
`;

const StyledProgramDesc = styled.p`
  max-width: 60ch;
  line-height: 1.5;
`;

const StyledProgramList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "image1 content1"
    "content2 image2"
    "image3 content3"
    "content4 image4";
  place-items: center;
  gap: 2em;
  padding: 6em 0;

  .company_image-wrapper {
  }

  .company__program {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .program_image1 {
    grid-area: image1;
  }

  .program_content1 {
    grid-area: content1;
  }

  .program_image2 {
    grid-area: image2;
  }

  .program_content2 {
    grid-area: content2;
  }

  .program_image3 {
    grid-area: image3;
  }

  .program_content3 {
    grid-area: content3;
  }

  .program_image4 {
    grid-area: image4;
  }

  .program_content4 {
    grid-area: content4;
  }
`;

const StyledProgramLink = styled(StyledLink)`
  padding: 1em 2em;
  background-color: var(--bg-primary);
  border: 2px solid rgba(0, 0, 0, 0.05);
  border-radius: 10px;
`;
/** styled components end */

type ProgramLinkProp = {
  label: string;
  url: string;
};

const ProgramLink: FC<ProgramLinkProp> = ({ label, url }) => {
  return <StyledProgramLink href={url}>{label}</StyledProgramLink>;
};

//@note Style the programs from side to side!
export const HomePrograms: FC = () => {
  return (
    <StyledContainer id={"programs"}>
      <StyledContent>
        <StyledHeader>Programs</StyledHeader>
        <StyledProgramList>
          {/* Program 1 */}
          <motion.div
            className={"company__img-wrapper program_image1"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8 }}
          >
            <StyledProgramImage
              src={"/img/strangers_card_bg.png"}
              alt={""}
              draggable={false}
            />
          </motion.div>
          <motion.div
            className={"company__program program_content1"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 1 }}
          >
            <StyledProgramHeader>Strangers</StyledProgramHeader>
            <StyledProgramDesc>
              At one time or another anyone may find themselves in a situation
              that may be new or different. However, a Stranger is one who comes
              into our environment from another country, tends to be “different”
              in ways that are familiar for us. Strangers are often despised
              because of their differences. A Breath From Heaven guides the
              Stranger to fit into a society that can be embraced and eventually
              become a place to grow with others.
            </StyledProgramDesc>

            {/* <ProgramLink label={"find out more"} url={""} /> */}
          </motion.div>

          {/* Program 2 */}
          <motion.div
            className={"company__img-wrapper program_image2"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 1 }}
          >
            <StyledProgramImage
              src={"/img/orphans_card_bg.png"}
              alt={""}
              draggable={false}
            />
          </motion.div>
          <motion.div
            className={"company__program program_content2"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8 }}
          >
            <StyledProgramHeader>Orphans</StyledProgramHeader>
            <StyledProgramDesc>
              A loss of parents because of death, disappearance, abandonment,
              separation, or desertion is what legally defines an orphan. In the
              midst of those defining circumstances, A Breath From Heaven looks
              at an Orphan as someone who needs love, support, direction, a
              goal, a dream. Orphans should always know that they are loved. Be
              part of the Village that brings renewed hope to an Orphan.
            </StyledProgramDesc>

            {/* <ProgramLink label={"find out more"} url={""} /> */}
          </motion.div>

          {/* Program 3 */}
          <motion.div
            className={"company__img-wrapper program_image3"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8 }}
          >
            <StyledProgramImage
              src={"/img/widow_card_bg.png"}
              alt={""}
              draggable={false}
            />
          </motion.div>
          <motion.div
            className={"company__program program_content3"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 1 }}
          >
            <StyledProgramHeader>Widows</StyledProgramHeader>
            <StyledProgramDesc>
              This category is usually overlooked by society after the mourning
              period. So many widows are unable to accomplish the simple task of
              the upkeep of their homes, coping with memories and loss of
              companionship. A Breath From Heaven feeds a widow the knowledge
              that they are not alone. There are others who can fill the gap of
              what is now difficult to achieve alone...be a companion, a helper
              of a Widow.
            </StyledProgramDesc>

            {/* <ProgramLink label={"find out more"} url={""} /> */}
          </motion.div>

          {/* program 4 */}
          <motion.div
            className={"company__img-wrapper program_image4"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 1 }}
          >
            <StyledProgramImage
              src={"/img/prisoners_card_bg.png"}
              alt={""}
              draggable={false}
            />
          </motion.div>
          <motion.div
            className={"company__program program_content4"}
            initial={{ opacity: 0, x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.8 }}
          >
            <StyledProgramHeader>Prisoners</StyledProgramHeader>
            <StyledProgramDesc>
              <q>And don't forget about the prisoners</q> - I Am
            </StyledProgramDesc>
            {/* <ProgramLink label={"find out more"} url={""} /> */}
          </motion.div>
        </StyledProgramList>
      </StyledContent>
    </StyledContainer>
  );
};
