import { FC } from "react";
import styled from "styled-components";

/**
 * While this website is in beta, this message will pop up for mobile devices in production.
 */

/** styled components */
const StyledContainer = styled.div`
  background-color: var(--bg-primary);
  justify-content: center;
  align-items: center;

  h1 {
    font-family: "Pacifico", cursive;
    font-size: 5em;
    font-weight: 900;
  }

  /* xs */
  @media (min-width: 375px) {
    display: flex !important;
  }

  /* sm */
  @media (min-width: 640px) {
  }

  /* md */
  @media (min-width: 768px) {
  }

  /* lg */
  @media (min-width: 1024px) {
    display: none;
  }

  /* xl */
  @media (min-width: 1280px) {
  }

  /* 2xl */
  @media (min-width: 1536px) {
  }
`;
/** styled components end */

export const UnderDevelopmentMessage: FC = () => {
  return (
    <StyledContainer>
      <h1>ABFH</h1>
    </StyledContainer>
  );
};
