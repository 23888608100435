import { FC } from "react";
import styled from "styled-components";

/** style components start */
const StyledContainer = styled.section`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

/* lg */
@media (min-width: 1024px) {
    display: flex;
    height: 60vh;
    width: 100%;
    background-color: var(--bg-primary);
}
`;

const StyledContent = styled.div``;

const StyledHeader = styled.h2`
font-size: 3em;
    font-weight: 700;
    margin-bottom: 3em;
`;

const StyledFeaturesContainer = styled.div`
width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 4em;
`;

const StyledFeature = styled.div`
    max-width: 50ch;
`;

const StyledSubHeader = styled.h3``;

const StyledDesc = styled.p``;

const StyledLink = styled.a`
    color: var(--text-primary);
    text-decoration: none;
    transition: all 0.10s ease-in-out;

    &:hover {
        text-decoration: underline;
    }
`;

/** style components end */

export const HomeFeatures: FC = () => {
    return (
        <StyledContainer>
            <StyledContent>
                <StyledHeader>Supporting Strangers in Need</StyledHeader>
                <StyledFeaturesContainer>
                    <StyledFeature>
                        <i className={"bx bx-child bx-lg"}></i>
                        <StyledSubHeader>Caring for Orphans</StyledSubHeader>
                        <StyledDesc>We provide a safe and nurturing environment for orphaned children, ensuring they receive love, education, and support.</StyledDesc>
                        {/* <StyledLink href={"#"}>Learn More <i className={"bx bx-chevron-right"}></i></StyledLink> */}
                    </StyledFeature>

                    <StyledFeature>
                        <i className={"bx bxs-heart bx-lg"}></i>
                        <StyledSubHeader>Aiding Widows in Need</StyledSubHeader>
                        <StyledDesc>We offer assistance and resources to widows, helping them rebuild their lives and find financial stability.</StyledDesc>
                        {/* <StyledLink href={"#"}>Learn More <i className={"bx bx-chevron-right"}></i></StyledLink> */}
                    </StyledFeature>

                    <StyledFeature>
                        <i className={"bx bx-run bx-lg"}></i>
                        <StyledSubHeader>Rehabilitating Prisoners</StyledSubHeader>
                        <StyledDesc>We work towards the reintegration of prisoners into society, providing them with skills, support, and opportunities for a fresh start.</StyledDesc>
                        {/* <StyledLink href={"#"}>Learn More <i className={"bx bx-chevron-right"}></i></StyledLink> */}
                    </StyledFeature>
                </StyledFeaturesContainer>
            </StyledContent>
        </StyledContainer>
    );
}