import { FC } from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { animate, motion } from "framer-motion";
import { Link } from "react-router-dom";

/** styled components start */
const StyledContainer = styled.section`
  height: 92.4vh;
  width: 100vw;
  background-color: var(--bg-primary);
  position: relative;

  &::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, var(--bg-primary)),
      url(/img/polka-dots.svg);
    height: 550px;
    width: 100%;
    inset: 0px;
    position: absolute;
  }
`;

const Content = styled.div`
  height: 100%;
  max-width: 69ch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 8%;
  text-align: left;
  position: relative;

  .hero__btn__wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    text-align: left;
    width: inherit;
    gap: 2em;
  }
`;

const Header = styled.h1`
  color: black;
  font-size: 3.2em;
  font-weight: 800;

  #first-word {
    color: var(--bg-tertiary);
  }
`;

const HeaderDesc = styled.p`
  color: black;
  margin-bottom: 2em;
  font-size: 1.2em;
  line-height: 1.2;
`;
/** styled components end */

export const HomeHero: FC = () => {
  return (
    <StyledContainer>
      <Content>
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          animate={{ opacity: 1, x: 0 }}
          transition={{ type: "spring", duration: 0.7, delay: 0.2 }}
        >
          <Header>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              E
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              m
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              p
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              o
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              w
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              e
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              r
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              i
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              n
            </motion.span>
            <motion.span
              id="first-word"
              transition={{ duration: 0.3 }}
              whileHover={{
                display: "inline-block",
                y: -15,
                cursor: "pointer",
              }}
            >
              g
            </motion.span>{" "}
            Lives Through Compassion and Support
          </Header>
          <HeaderDesc>
            A Breath From Heaven serves to educate, equip and empower others to
            never be alone by providing resources and engaging them within their
            communities
          </HeaderDesc>
          <div className="hero__btn__wrapper">
            <Link to={"donate"}>
              <Button label="donate" />
            </Link>
            {/* <Link to={'volunteer'}><Button label="volunteer" /></Link> */}
          </div>
        </motion.div>
      </Content>
    </StyledContainer>
  );
};
