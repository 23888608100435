import { FC } from "react";
import styled from "styled-components";
import { Button } from "../Button";
import { animate, motion } from "framer-motion";
import { Link } from "react-router-dom";

/** styled components start */
const StyledContainer = styled.section`
  height: 100vh;
  width: 100vw;
  background-color: var(--bg-primary);
  position: relative;

  &::before {
    content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent, var(--bg-primary)),
      url("/img/polka-dots.svg");
    height: 550px;
    width: 100%;
    inset: 0px;
    position: absolute;
  }

  /* lg */
  @media (min-width: 1024px) {
    display: flex;
  }
`;

const StyledContent = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10em;
  position: relative;
`;

const StyledHeroHeader = styled.h1`
  font-size: 3em;
  font-weight: 700;
`;

const StyledHeroSubHeader = styled.p`
  font-size: 1.2em;
  max-width: 80ch;
  text-align: center;
  line-height: 1.5;
`;

const StyledHeaderLink = styled.a`
  color: var(--text-primary);
`;

/** styled components end */

export const DonateHero: FC = () => {
  return (
    <StyledContainer>
      <StyledContent>
        <StyledHeroHeader>
          Support Our Commitment to Help Those in Need
        </StyledHeroHeader>
        <StyledHeroSubHeader>
          Your donation can make a difference in the lives of Strangers,
          Orphans, Widows, and Prisoners. Join us in providing hope and support
          to those who need it most.
        </StyledHeroSubHeader>
        {/* <Link to={""}>
          <Button label="learn more" />
        </Link> */}
      </StyledContent>
    </StyledContainer>
  );
};
