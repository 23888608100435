import { FC } from "react";
import { NavigationBar } from "../../compound/NavigationBar";
import { Footer } from "../../compound/Footer";
import { DonateHero } from "./DonateHero";
import { DonateOptions } from "./DonateOptions";

export const Donate: FC = () => {
  return (
    <div>
      <NavigationBar />
      <DonateHero />
      <DonateOptions />
      <Footer />
    </div>
  );
};
