import { FC } from "react";
import styled from "styled-components";

/** styled components start */
const StyledContainer = styled.section`
  background-color: var(--bg-secondary);
`;

const StyledContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const StyledHeader = styled.h2`
  font-size: 4em;
  font-weight: 700;
  text-align: center;
  max-width: 35ch;
`;

const StyledDesc = styled.p`
  font-size: 1.5em;
  max-width: 73ch;
  line-height: 1.4;
  font-weight: 500;
`;
/** styled components end */

export const HomeAbout: FC = () => {
  return (
    <StyledContainer id={"about"}>
      <StyledContent>
        <StyledHeader>
          A Breath From Heaven: Empowering Lives Through Christian Values
        </StyledHeader>
        <StyledDesc>
          At A Breath From Heaven, we are dedicated to helping Strangers,
          Orphans, Widows, and Prisoners by providing them with the support and
          resources they need. Our Christian values guide us in our mission to
          make a positive impact on the lives of those who are often overlooked.
        </StyledDesc>
      </StyledContent>
    </StyledContainer>
  );
};
